:root{
	--bgColor: #131d2c;
	--fgColor: #232d3c;
	--linkColor: #b6b6b6;
	--textColor: #f2f2f2;
}

/*==================
  Bootstrap Imports
===================*/

.card{
	margin: 0 auto; /* Added */
	float: none; /* Added */
}

.bg-custom{
  background-color: var(--fgColor);
  color: var(--textColor);
  font-weight: bold;
}
.nav-item{
	color: var(--textColor) !important;
}
.nav-link{
  color: var(--textColor) !important;
  transition: color .2s;
}
.navLink{
  color: var(--textColor) !important;
  text-decoration: none !important;
  transition: color .2s;
}
.nav-link:hover{color: var(--linkColor) !important;}
.navLink:hover{color: var(--linkColor) !important;}

.dropdown-menu{
  background-color: var(--bgColor) !important;
}
.dropdown-item:hover{
  background-color: var(--bgColor) !important;
}

.navbar-toggler{
  mask-image: url(icon/bars.svg);
	mask-size: 100% 100%;
  background-color: var(--linkColor) !important;
  width: 1.5rem;
  height: 1.5rem;
  transition: background-color .2s;
}
.navbar-toggler:hover{
  background-color: var(--textColor) !important;
  width: 1.5rem;
  height: 1.5rem;
}

.text-color{
  color: var(--textColor) !important;
}
.navbar-toggler-icon{
  background-image: none !important;
}

.btn-outline-dark{
  color: var(--textColor) !important;
  border-color: var(--textColor) !important;
}
.btn-outline-dark:hover{
  font-weight: normal !important;
  color: var(--linkColor) !important;
  border-color: var(--linkColor) !important;
  background-color: rgba(0,0,0,0) !important;
}

.card{
  background-color: var(--fgColor) !important;
  color: var(--textColor) !important;
  border-color: var(--textColor) !important;
}

.card-footer{
  background-color: var(--bgColor) !important;
}
.btn-link{
  color: var(--linkColor) !important;
}
.btn-link:hover{
  color: var(--textColor) !important;
}
.form-control{
  color: var(--textColor) !important;
  background-color: var(--bgColor) !important;
  border-color: var(--linkColor) !important;
}

.modal-content{
  border: 1px solid var(--bgColor) !important;
}
.modal-header{
  background-color: var(--fgColor);
  border: 0 !important;
}
.modal-title{
  color: var(--linkColor);
}
.modal-body{
  background-color: var(--bgColor);
  color: var(--textColor);
}
.close{
  color: var(--linkColor) !important;
  text-shadow: 0 1px 0 var(--bgColor) !important;
  transition: color .2s;
}
.close:hover{
  color: var(--textColor) !important;
}

.jumbotron{
  background-color: var(--fgColor) !important;
  color: var(--textColor);
}
body {
	background-color: var(--fgColor) !important;
}
.text-color {
	color: var(--textColor);
}
#jumbotron-home{
	margin-top: 5%;
	margin-left: 25%;
	margin-right: 25%;
}
  
.carousel{
	margin-left: auto;
	margin-right: auto;
	max-width: 720px;
	max-height: 600px;
	height: 500px;
	width: auto;
	/* background-color: black; */
}

.carousel-caption{
	margin-left: auto;
	margin-right: auto;
	width: inherit;
	height: 50px;
	background-color: black;
}

/*=======
  Icons
=======*/

.icon{
  display: inline-block;
	background-color: var(--linkColor);
	height: 1em;
	width: 1em;
	mask-size: 100% 100%;
	margin-left: .25em;
	margin-right: .25em;
  transition: background-color .2s;
}

.icon:hover{
	background-color: var(--textColor);
}

.dead{
  display: none;
}

.eventPin{
  mask-image: url(icon/pin.svg);
  position: absolute;
  left: 1em;
  top: 2.25em;
}

.logo    {mask-image: url(icon/orsmash.svg); }
.generic {mask-image: url(icon/www.svg);     }
.plus    {mask-image: url(icon/plus.svg);    }
.minus   {mask-image: url(icon/minus.svg);   }
.pin     {mask-image: url(icon/pin.svg);     }
.info    {mask-image: url(icon/info.svg);    }
.bars    {mask-image: url(icon/bars.svg);    }

/*icon minus recoloring for legal reasons,
coincidentally they all have white logos so
we can use a black background for now*/
.brand{
  display: inline-block;
	height: 1em;
	width: 1em;
  background-size: 100% 100%;
  background-repeat: none;
  background-color: black;
  border: 1px black solid;
  border-radius: 3px;
	margin-left: .25em;
	margin-right: .25em;
}
.discord-style{
	display: inline-block;
	  height: 1.5em;
	  width: 1.5em;
	background-size: 100% 100%;
	margin-right: 4px;
	background-repeat: none;
  }
  .noHover{
    pointer-events: none !important;
}
.twitter {background-image: url(icon/twitter.svg); }
.twitch  {background-image: url(icon/twitch.svg);  }
.facebook{background-image: url(icon/facebook.svg);}
.github  {background-image: url(icon/github.svg);  }
.discord {background-image: url(icon/discord.svg); }
.calendar{background-image: url(icon/calendar.svg);}
.people  {background-image: url(icon/people.svg);}
.location{background-image: url(icon/location.svg);}
.discord {background-image: url(icon/discord-mark-white.svg); }

/*
.home   {mask-image: url(icon/home.svg);}
.palette{mask-image: url(icon/palette.svg);}
.mobile{mask-image: url(icon/mobile.svg);}
.desktop{mask-image: url(icon/desktop.svg);}
*/

/*=======
  Other
=======*/

#video-con {
	margin-top: 5%;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px; 
    height: 0; 
    overflow: hidden;
}

#video-con iframe, video-con object, .video-con embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

a{
	color: var(--linkColor) !important;
	text-decoration: none;
  transition: color .2s;
}

a:hover{
	color: var(--textColor) !important;
}

body{
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	background-color: var(--bgColor) !important;
	/* font-family: 'Big Shoulders Text', cursive; */
	/* font-size: 16pt; */
}

.outerDiv{
  margin-top: 5em;
	background-color: var(--bgColor);
}

button{
	background-color: var(--bgColor);
	border: 0px;
	border-radius: .5em;
	color: var(--linkColor);
	font: inherit;
	font-size: inherit;
}

button:hover{
	color: var(--textColor);
	font: inherit;
	font-size: inherit;
}

code{
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

footer{
	background-color: var(--fgColor);
	bottom: 0;
	left: 0;
	padding: .25em;
	position: fixed;
	width: 100%;
	z-index: 100;
}

/* h2{
  font-size: 1em;
  margin-bottom: -1em;
} */

hr{
	border: none;
	border-top: 1px solid var(--textColor);
	height: 0px;
	margin: 0;
	padding: 0;
}

iframe{
	height: 15em;
	overflow: scroll;
	width: 100%;
}

li{
	color: var(--linkColor);
	display: inline-block;
	padding-left: .5em;
	padding-right: .5em;
}

li:hover{
	color: var(--textColor);
	cursor: pointer;
}

ul{
	display: block;
	margin: 0;
	padding: 0;
	text-align: center;
}

#rankingImg{
	padding-top: auto;
}

.SocialMedia{
	margin-top: 25px;
	overflow-y: auto;
	text-align: center;
}

.SocialMedia img{
	background-color: var(--bgColor);
	border-radius: 50%;
	display: block;
	margin: auto;
	overflow: hidden;
	width: 30%;
}

#arrow-down{
	max-width: 20%;
	max-height: auto;
}

#button-down{
	background-color: rgba(255, 255, 255, .0);
	max-width: 30%;
}

#btb-crowd{
	max-width:60%;
    height:auto;
}

#events-div{
	background: rgba(242, 242, 242, 0.0);
	margin-left: 20%;
	margin-right: 20%;
	margin-bottom: 20%;
}

#getting-started-item{
	background-color: rgba(0, 0, 0, 0.0);
	width: fit-content;
	margin-top: 1%;
	margin-bottom: 50%;
}

#getting-started-item1{
	background-color: rgba(0, 0, 0, 0.0);
	width: fit-content;
	margin-top: 1%;
	margin-bottom: 50%;
}

#getting-started-item2{
	background-color: rgba(0, 0, 0, 0.0);
	margin-top: 1%;
	margin-bottom: 50%;
}

#getting-started-item3{
	background-color: rgba(0, 0, 0, 0.0);
	width: fit-content;
	margin-top: 1%;
	margin-bottom: 50%;
}

#getting-started-div{
	margin-left: 20%;
	margin-right: 20%;
	margin-bottom: 20%;
}

#header{
	background-color: var(--fgColor);
	border-bottom: 1px solid var(--textColor);
	font-size: 1.5em;
	margin: 0;
	margin-bottom: 20px;
	position: sticky;
	text-align: center;
	top: -20px;
}

#header a{
	position: absolute;
	right: 0;
	top: 0;
}

#invisible{
	display: none;
}

#rankings-div{
	margin-left: 20%;
	margin-right: 20%;
	margin-bottom: 20%;
}

#tall{
	height: 100%;
	top: 0%;
}

.fade-in {
	animation: fadeIn ease 1s forwards;
	opacity: 0;
  }
  
  @keyframes fadeIn {
	0% {
	  opacity: 0;
	}
	100% {
	  opacity: 1;
	}
  }
  .custom-card {
	border: 2px solid white !important; /* Set border to white and increase border width */
	transition: transform 0.3s, box-shadow 0.3s; /* Add transition for transform and box-shadow */
  }
  
  .custom-card:hover {
	transform: scale(1.04); /* Scale up and add a subtle tilt on hover */
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.3); /* Add a subtle box-shadow effect on hover */
  }